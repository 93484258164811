.home, .header{
    animation: 1s ease-in 0s 1 slideInFromLeft;
}

.mid {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.home-container{
    font-family: 'CoolFont', sans-serif;
}
.home-title {
    color: #fff;
    animation: tiktok 500ms linear infinite, fade 1.5s ease-in;
    cursor: default;
    font-size: 4vh;
}

.home-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0;
    margin: 0;
}

.home-tabs-time {
    cursor: default;
    font-size: 3vh;
    color: gray;
    border-radius: 10px;
    padding: 3px 7px 5px 7px;
    animation: fade 2s ease-in;
    background-color: rgba(166, 166, 166, 0.2);
}

.home-tabs span{
    margin: 0 5px 0 5px;
}

.home-tabs span:not(.tabs-time){
    cursor: crosshair;
}

.home-tabs span::selection, .title h1::selection {
    background-color: transparent;
}

@keyframes fade {
    0%, 72% {opacity: 0}
    100% {opacity: 1}
}

@keyframes tiktok {
    0%, 100% {
        text-shadow: -1.5px -1.5px 0 #0ff, 1.5px 1.5px 0 #f00;
    }
    25% {
        text-shadow: 1.5px 1.5px 0 #0ff, -1.5px -1.5px 0 #f00;
    }
    50% {
        text-shadow: 1.5px -1.5px 0 #0ff, 1.5px -1.5px 0 #f00;
    }
    75% {
        text-shadow: -1.5px 1.5px 0 #0ff, -1.5px 1.5px 0 #f00;
    }
}


* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

:root {
    --font-size-small: 1.8rem;
    --font-size-normal: 2.2rem;
    --font-size-medium: 2.8rem;
    --font-size-medium-1: 3.6rem;
    --font-size-large: 5.5rem;
    --font-size-huge: 7.5rem;
    --font-stack: 'Hk Grotesk', sans-serif;

    --line-height-normal: 1.7;
    --line-height-small: 1.2;

    --black: #000;
    --pink: #2252a4;
    --white: #fff;
    --white-1: #b8b4b4;


    --container-max-width: 1180px;
    --container-normal-width: 800px;
    --container-medium-width: 700px;
    --container-small-width: 500px;

    --gutter-huge: 12rem;
    --gutter-medium: 6rem;
    --gutter-normal: 3rem;
    --gutter-small-1: 2.5rem;
    --gutter-small: 2rem;

    --border-light: 1px solid rgb(36, 35, 35);
}

html {
    font-size: 62.5%; /* (16/10)*100 = 62.5% => 1rem = 10px */
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@media(max-width: 1000px) {
    html {
        font-size: 52%;
    }
}

body {
    font-size: var(--font-size-small);
    font-family: var(--font-stack);
    font-weight: 400;
    color: var(--white-1);
    line-height: var(--line-height-normal);
    background: var(--black);
    overflow-x: hidden;
}

.languages span, .backend span, .frontend span{
    font-size: 2.2rem;
}
.project-tech {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 330px) {
    .project-tech {
        display: block;
    }
}
.dated-section{
    display: flex;
}

.dated-section h4{
    flex-grow: 1;
}

.resume-container{
    display: flex;
    align-items: center;
    justify-items: center;
}

.resume-container .social-link:hover {
    transform: scale(1.2);
}

.row {
    max-width: var(--container-max-width);
    margin: 0 auto;
}

@media (max-width: 1340px) {
    .row {
        max-width: 1100px;
    }
}

@media (max-width: 1240px) {
    .row {
        padding: 0 var(--gutter-medium);
    }
}

@media(max-width: 600px) {
    .row {
        padding: 0 var(--gutter-normal);
    }
}

@media(max-width: 600px) {
    .row {
        padding: 0 var(--gutter-small);
    }
}

section {
    padding: var(--gutter-huge) 0;
    border-bottom: var(--border-light);
}

img {
    object-fit: contain;
    max-width: 100%;
}

/* --------------------------------------- */
/* ----- Headlines and Paragraphs ----- */
/* --------------------------------------- */

h1,
h2,
h3 {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    line-height: var(--line-height-small);
    color: var(--white);
}

.heading-primary {
    line-height: 1;
    font-size: var(--font-size-huge);
    font-weight: 400;
}

@media(max-width: 900px) {
    .heading-primary {
        font-size: 6.5rem;
    }
}

h2 {
    font-size: var(--font-size-large);
    margin-bottom: var(--gutter-medium);
}

h2::after {
    content: '';
    display: block;
    height: 2px;
    width: 10rem;
    background: var(--pink);
    margin: var(--gutter-small) 0;
}

h3 {
    font-size: var(--font-size-medium-1);
    margin-bottom: var(--gutter-small);
}

@media(max-width: 500px) {
    .heading-primary {
        font-size: var(--font-size-large);
    }

    h2 {
        font-size: var(--font-size-medium-1);
    }

    h3 {
        font-size: var(--font-size-medium);
    }
}

p {
    margin: var(--gutter-small-1) 0;
}

@media(max-width: 900px) {
    p {
        max-width: var(--container-small-width);
    }
}

/* --------------------------------------- */
/* ----- Buttons and Links ----- */
/* --------------------------------------- */

a {
    color: var(--white);
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    transition: all .2s;
    outline: 2px solid transparent;
}

::-moz-focus-inner {
    border: 0;
}

button:focus,
a:focus {
    /*outline: ;*/
    /*outline: 2px solid var(--pink);*/
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) a:focus {
    outline: none;
}

.link:hover {
    color: var(--pink);
}

.btn {
    position: relative;
    display: inline-block;
    padding: 1rem 4.2rem;
    text-decoration: none;
    color: inherit;
    border: 1px solid  var(--pink);
    font-weight: 400;
}

.btn:focus {
    outline: none;
}

.btn::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    background: var(--white);
    height: 100%;
    z-index: -1;
    transition: all 0.2s cubic-bezier(1, 0.68, 0.16, 0.9);
}

.btn:hover::after,
.btn:focus::after {
    right: 0;
    background: var(--pink);
}

.btn--pink {
    background: var(--pink);
    transition: all 0.2s;
}

.btn--pink::after {
    display: none;
}

.btn--pink:hover,
.btn--pink:focus {
    background: transparent;
}

.link__text {
    position: relative;
    display: inline-block;
    padding: .6rem;
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid var(--pink);
}

.link__text::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    background: var(--white);
    height: 100%;
    z-index: -1;
    transition: all 0.2s cubic-bezier(1, 0.68, 0.16, 0.9);
}

.link__text:focus {
    outline: none;
}

.link__text:hover::after,
.link__text:focus:after {
    right: 0;
    background: var(--pink);
}

.link__text span {
    padding-left: 1rem;
    font-family: sans-serif;
}

/* ----- Back to Top ----- */

.back-to-top {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.5rem;
    width: 5.5rem;
    background-color: var(--pink);
    border-radius: 50%;
    z-index: 10;
    /*visibility: hidden;*/
    transition: all .4s;
}

.back-to-top__image {
    height: 70%;
}

@media(max-width: 900px) {
    .back-to-top {
        right: 2rem;
    }
}

@media(max-width: 500px) {
    .back-to-top {
        right: 1rem;
    }
}

/* --------------------------------------- */
/* ----- Navigation ----- */
/* --------------------------------------- */

.nav {
    display: flex;
    justify-content: flex-end;
    padding: var(--gutter-normal) 0;
}


.nav-toggle-button {
    position: absolute;
    top: 3rem;
    right: 4rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
}

.nav-toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    transition: height 300ms linear;

}

.nav-toggle-button:active {
    background-color: var(--pink);
    transform: scale(1.2);
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: translateX(-2em);
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 600px) {

    .nav-container {
        padding: 0;
        margin: 0;
    }

    .nav {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    .nav-toggle-button {
        display: flex;
    }

    .navbar-links {
        display: none;
        width: 100%;
        padding: 2rem;
        transition: all 12s ease-out;
    }

    .navbar-links ul li {
        display: block;
        margin-right: auto;
        text-align: left;
    }

    .navbar-links ul {
        width: 100%;
        flex-direction: column;
    }

    .navbar-links ul li a {
        padding: .1rem;
    }

    .navbar-links.active {
        /*transform: translateX(calc(100vw - 100%));*/
        display: flex;
        animation: 0.6s ease-in 0s 1 slideInFromLeft;
    }
}

.nav__items {
    display: flex;
    list-style: none;
}

.nav__item:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    tbody, thead {
        float: left;
    }

    thead {
        min-width: 120px
    }

    td, th {
        display: block
    }
}

.back-to-top__image:hover, .back-to-top:hover {
    cursor: pointer;
    transform: scale(1.3);
}

.back-to-top__image:focus {
    transform: scale(1.3);
}

a {
    outline: none;
}

.nav__item:not(:last-child) {
    margin-right: var(--gutter-medium);
}

@media (max-width: 400px) {
    .nav__item:not(:last-child) {
        margin-right: var(--gutter-normal);
    }
}

.nav__link {
    position: relative;
    display: inline-block;
    padding: 1rem 0;
    text-decoration: none;
    color: inherit;
    transition: all .2s;
}

.nav__link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    display: inline-block;
    height: 1rem;
    background: var(--white);
    transition: all 0.25s cubic-bezier(1, 0.68, 0.16, 0.9);
}

.nav__link:hover {
    color: var(--pink);
}

.nav__link:hover::after {
    right: 0;
    height: 2px;
    background: var(--pink);
}

/* --------------------------------------- */
/* ----- Header ----- */
/* --------------------------------------- */

.header {
    /*background: linear-gradient(rgba(0,0,0, .1), rgba(0,0,0, .4)),*/
    /*url('../.././images/header.jpg');*/
    height: 100vh;
    /*background-position: center;*/
    /*background-attachment: fixed;*/
    font-size: var(--font-size-normal);

    background: url('../.././images/header.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
}

.header__text p {
    text-align: left;
    margin: 1.5rem 0 3.5rem;
    max-width: var(--container-medium-width);
    font-family: 'Jost', sans-serif;
    font-size: var(--font-size-medium);
}

@media(max-width: 500px) {
    .header {
        text-align: center;
    }
}

/* --------------------------------------- */
/* ----- Work ----- */
/* --------------------------------------- */

.work__box {
    display: flex;
    align-items: end;
}

@media(max-width: 900px) {
    .work__box {
        align-items: initial;
        flex-direction: column-reverse;
    }
}

.work__box:not(:last-child) {
    margin-bottom: 25rem;
}

@media(max-width: 500px) {
    .work__box:not(:last-child) {
        margin-bottom: 20rem;
    }
}

.work__links {
    display: flex;
    align-items: center;
}

.work__text {
    flex: 0 0 50%;
    margin: 0;
    padding: 0;
}

.work__list {
    /* list-style-type: square; */
    list-style-position: inside;
    margin-bottom: var(--gutter-normal);
}

.project-description{
    padding-top: 1.2rem;
    font-size: 1.75rem;
}

.work__code {
    display: block;
    height: 3rem;
    margin-left: var(--gutter-normal);
    transition: all .3s;
}

.work__code:hover {
    transform: scale(1.2);
}

.work__image-box {
    margin-bottom: var(--gutter-normal);
}

.work_title h4 {
    margin: 0;
    padding: 0;
    font-weight: 0;
}

.work_title span {
    margin: 0;
    padding: 0;
    font-weight: 100;
}

@media(min-width: 901px) {
    .work__image-box {
        flex: 1;
        margin: 0 0 0 10rem;
    }
}

@media(max-width: 900px) {
    .work__code {
        height: 4rem;
    }
}

/* --------------------------------------- */
/* ----- Clients ----- */
/* --------------------------------------- */

.client__logos {
    display: flex;
    justify-content: space-between;
    filter: invert(100%) grayscale(100%) opacity(.8);
}

.client__logo {
    height: 6.5rem;
    max-width: 18rem;
}

@media(max-width: 1240px) {
    .client__logos {
        overflow-x: scroll;
        padding: var(--gutter-normal);
    }

    .client__logo:not(:last-of-type) {
        margin-right: var(--gutter-medium);
    }
}

/* --------------------------------------- */
/* ----- About ----- */
/* --------------------------------------- */

.about__content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

@media(max-width: 900px) {
    .about__content {
        flex-direction: column-reverse;
        align-items: initial;
    }
}

.about__photo-container {
    margin-bottom: var(--gutter-normal);
}

@media(min-width: 901px) {
    .about__text {
        flex: 0 0 55%;
    }

    .about__photo-container {
        flex: 1;
        margin: 0 var(--gutter-huge) 0 0;
    }
}

/* --------------------------------------- */
/* ----- Contact ----- */
/* --------------------------------------- */

.contact__info {
    max-width: var(--container-medium-width);
}

/* --------------------------------------- */
/* ----- Footer ----- */
/* --------------------------------------- */

.footer {
    text-align: center;
    padding: var(--gutter-medium) 0 var(--gutter-normal);
}

.footer__social-links {
    display: flex;
    justify-content: center;
    padding: var(--gutter-normal) 0;
    list-style: none;
}

.footer__social-link-item:not(:last-of-type) {
    margin-right: var(--gutter-small);
}

.footer__social-image {
    height: 3.6rem;
}

.footer__github-buttons {
    display: flex;
    justify-content: center;
}

.footer__github-buttons iframe {
    height: 2.5rem;
    width: 84px;
    transform: translateY(3px);
}

@media(max-width: 500px) {
    .footer {
        padding: var(--gutter-medium) 0;
    }
}

.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    top: 35%;
    /*left: 50%;*/
    position: absolute;
}

.marquee span {
    font-size: 150px;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 70s linear infinite;
    /*animation-delay: 0.1ms;*/
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}
