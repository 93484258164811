.quotchy-container{
    font-family: 'CoolFont', sans-serif;
    overflow: hidden;
}
/*body{*/
/*    overflow: hidden;*/
/*}*/

.quotchy-card {
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    text-align: center;
    padding: 0 40px 0 40px;
    font-size: 2em;
    font-style: italic;
}
/*::-webkit-scrollbar {display:none;}*/
/*html {*/
/*    overflow: scroll;*/
/*    overflow-x: hidden;*/
/*}*/
/*::-webkit-scrollbar {*/
/*    width: 0;  !* Remove scrollbar space *!*/
/*    height: 0;*/
/*    background: transparent;  !* Optional: just make scrollbar invisible *!*/
/*    display: none;*/
/*}*/

/* width */