.container {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.buttons {
    display: inline-block;
    padding-top: 0.5em;
}

.yes, .ofc {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    line-height: 1em;
    font-weight: 600;
    border-radius: 1em;
    padding: 0.7em 1em;
    border: none;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: #fff;
    margin: 1em;
}
