@font-face {
    font-family: 'Hk Grotesk';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/HKGrotesk-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Jost-Regular.ttf') format('woff');
}

body{
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2252a4;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #2d62ba;
}